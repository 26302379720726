<style lang="scss" scoped>
/deep/ .comp-exchange-complete {
    margin-top: 6vh !important;
    max-width: 1200px;
    .item-card {
        position: relative;
        width: 100%;
        height: auto;
        margin: 15px auto 0;
        padding-bottom: 35px;
        background-color: #f5f5f5;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        overflow: hidden;
        .image {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .count {
            padding: 0 5px;
            .el-input-number--mini {
                width: 100%;
            }
        }
        .title {
            position: relative;
            height: 60px;
            padding: 0 4px;
            line-height: 1.6;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
        .buttons {
            width: 92%;
            position: absolute;
            bottom: 5px;
            left:4%;
            .el-button--mini.is-circle {
                padding: 4px;
            }
            .btn {
                padding: 5px;
            }
        }
        .el-image {
            width: 100%;
            aspect-ratio: 1.2;
            .image-slot {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .el-input-number--mini .el-input-number__decrease, 
        .el-input-number--mini .el-input-number__increase {
            width: 20px;
        }
    }
}
.assets {
    font-size: 13px;
    border-bottom: 1px dashed #eee;
    padding: 5px 0;
    .fl {
        max-width: 96%;
    }
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .asset-title {
        vertical-align: middle;
        line-height: 1.5;
        white-space: normal;
        display: inline-block;
    }
}
</style>

<template>
    <div>
        <el-dialog :title="title" custom-class="c-el-dialog comp-exchange-complete" :visible.sync="showDialog" 
            :before-close="_close" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="15">
                        <el-form-item label="归还人" prop="principal">
                            <el-select class="c-pw100" v-model="dialogData.principal" filterable placeholder="请选择归还人"
                                :default-first-option="true" @change="loadPendingBill">
                                <el-option v-for="item in allPrincipal" :value="item.name" :key="item.id">
                                    <span class="fl">
                                        {{ item.name }}
                                        <span class="col_info mrgl5" v-if="item.phone">{{ item.phone }}</span>
                                    </span>
                                    <span class="fr col_info">{{ item.company && item.company.name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <template v-if="dialogData.id">
                        <el-col :xs="24" :sm="12">
                            <el-form-item label="使用者">
                                <el-input class="c-pw100" :value="dialogData.assetUser && dialogData.assetUser.name" readonly></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24">
                            <el-form-item label="">
                                <el-row class="mrgb10">
                                    <el-button type="primary" :plain="scanCodeType !== 1" @click="scanBarcode()"><i class="fa fa-barcode"></i> 扫条形码</el-button>
                                    <el-button type="primary" :plain="scanCodeType !== 2" @click="scanQrCode()"><i class="fa fa-qrcode"></i> 扫二维码</el-button>
                                </el-row>
                                <el-row>
                                    <el-autocomplete :ref="refAutoComplete" class="c-pw100" v-model="keyword" :trigger-on-focus="false" clearable :fetch-suggestions="querySearchAsync" 
                                        placeholder="资产名称/条形码" @select="handleSelect" @keyup.enter.native="execSelect()" @clear="clearAutoComplete">
                                        <template slot-scope="{ item }">
                                            <dl class="assets">
                                                <dd class="fl">
                                                    <el-image class="asset-image" :src="uploadBaseUrl + item.imageUrl">
                                                        <div slot="error">
                                                            <i class="el-icon-picture-outline error-image"></i>
                                                        </div>
                                                    </el-image>
                                                    <span class="asset-title">
                                                        <span>{{ mxTranceAssetData(item) }}</span>
                                                        <span :class="mxTranceAssetData(item) ? '' : 'mrgl10'">{{ item.value }}</span>
                                                        <span class="mrgl5 col_danger" v-if="item.maxCount >= 0 && !$root.isPc">【库存：{{ item.maxCount }}】</span>
                                                    </span>
                                                </dd>
                                                <dd class="fr col_danger" v-if="item.maxCount >= 0 && $root.isPc">【库存：{{ item.maxCount }}】</dd>
                                                <div class="clearb"></div>
                                            </dl>
                                        </template>
                                    </el-autocomplete>
                                </el-row>
                                <el-row :gutter="10">
                                    <template v-for="(item, index) in dialogData.returnItems">
                                        <el-col :xs="8" :sm="6" :md="4" :lg="4" :key="'ItemCard' + index">
                                            <div class="item-card">
                                                <el-image :src="uploadBaseUrl + item.imageUrl" :preview-src-list="[uploadBaseUrl + item.imageUrl]" fit="cover">
                                                    <div slot="error" class="image-slot">
                                                        <img src="/static/images/icons/image-failed.png" />
                                                    </div>
                                                </el-image>
                                                <div class="title">{{ item.assetName }}</div>
                                                <div class="count">
                                                    <el-input-number v-model="item.count" :max="item.maxCount" :min="0" v-if="item.maxCount && item.maxCount > 0"
                                                        :disabled="item.needSerialNumber" size="mini"></el-input-number>
                                                    <el-input-number v-model="item.count" :min="0" v-else
                                                        :disabled="item.needSerialNumber" size="mini"></el-input-number>
                                                </div>
                                                <div class="buttons">
                                                    <el-button class="btn fl" size="mini" type="primary" icon="el-icon-edit" 
                                                        v-if="item.needSerialNumber" @click="openScanCode(item)">序列号</el-button>
                                                    <el-button class="btn fr" size="mini" type="danger" icon="el-icon-delete"
                                                        @click="_delete(item, index)"></el-button>
                                                    <div class="clearb"></div>
                                                </div>
                                            </div>
                                        </el-col>
                                    </template>
                                </el-row>
                            </el-form-item>
                        </el-col>
                    </template>
                    <el-col :xs="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" :rows="3" v-model="dialogData.remark" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button @click="_close()">取消</el-button>
                <el-button type="primary" @click="_save()">确认换电入库</el-button>
            </span>
        </el-dialog>

        <!--扫码getScanSerials-->
        <scan-serial :ref="refToScanSerial" @scanSerials="setAssetDatas"></scan-serial>
        <!--调用摄像头扫码-->
        <html-scan :ref="refHtmlScan" @success="htmlScanSuccess(arguments)"></html-scan>
    </div>
</template>

<script>
import stockCommon from "./js/stockCommon"
import ScanSerial from "./scanSerial"
import HtmlScan from "@/components/HtmlScan"
import { getStockCompany, getStockWarehourse } from "@/utils/storages"
import * as funCommon from "@/api/common"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "compStockExchangeIn",
    mixins: [stockCommon],
    components: { ScanSerial, HtmlScan },
    data() {
        return {
            refToScanSerial: "refStockExchangeInToScanSerial",
            refAutoComplete: "refStockExchangeInToAutoComplete",
            refHtmlScan: "refStockExchangeInStockToHtmlScan",
            showDialog: false,
            companyItem: {},
            warehourseItem: {},
            dialogData: {},
            defaultDialogData: {
                id: "",
                companyId: "",
                warehouseId: "",
                warehouse: null,
                assetUserId: "",
                assetUser: null,
                principal: "",
                remark: "",
                items: [],
                returnItems: [],
                attachments: [],
                status: null,
                creatorId: "",
                creationTime: ""
            },
            formRefName: "refStockExchangeIn",
            formRules: {},
            allPrincipal: [],
            keyword: "",
            title: "",
            scanCodeType: 1
        }
    },
    methods: {
        async open(row) {
            this.title = "换电入库"
            // 设置默认值
            this.setDefaultData()
            if (this.warehourseItem && this.warehourseItem.id) {
                this.title = `换电入库【${this.companyItem.name}-${this.warehourseItem.name}】`
            }
            await this.getAllPrincipal()
            // 列表上进来的，自动选中
            if (row && row.id) {
                await this.loadPendingBill(row.principal)
            }
            this.showDialog = true
        },
        setDefaultData() {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            this.dialogData.returnItems = []
            // 默认仓库
            this.companyItem = getStockCompany()
            this.warehourseItem = getStockWarehourse()
            this.dialogData.companyId = this.companyItem && this.companyItem.id || ""
            this.dialogData.warehouseId = this.warehourseItem && this.warehourseItem.id || ""
        },
        async loadPendingBill(v) {
            await funStock.getExchangePendingBill({ warehouseId: this.dialogData.warehouseId, principal: v }).then(res => {
                if (res) {
                    for (let i in this.dialogData) {
                        if (res[i] !== undefined) {
                            this.dialogData[i] = res[i]
                        }
                    }
                    this.dialogData.assetUserId = res.assetUser && res.assetUser.id || ""
                } else {
                    this.warningMsg(`${v}，没有待处理的换电记录！`)
                    this.setDefaultData()
                }
                this.dialogData.principal = v || ""
            })
        },
        querySearchAsync(queryString, cb) {
            // 查询商品
            var restaurants = []
            if (!queryString) {
                cb(restaurants)
                return false
            }
            var query = { 
                categoryId: null,
                companyId: "",
                ownerType: this.valOwnerAssetUser() || null,
                ownerId: this.dialogData.assetUserId || "",
                keywords: queryString,
                maxResultCount: 20
            }
            funStock.getStockSearch(query).then(res => {
                for (var i in res) {
                    var item = res[i]
                    var temp = {}
                    temp.id = item.id
                    temp.value = item.name
                    temp.barCode = item.barCode
                    temp.imageUrl = item.imageUrl || ""
                    temp.needSerialNumber = item.hasSerialNumber
                    temp.category = item.category
                    temp.maxCount = this.dialogData.assetUserId ? item.stock : -1
                    restaurants.push(temp)
                }
                cb(restaurants)
            }).catch(e => {
                this.$alert('资产加载出错!')
                return false
            })
        },
        handleSelect(item, callback) {
            this.clearAutoComplete()
            if (this.dialogData.assetUserId && item.maxCount === 0) {
                this.warningMsg(`${item.value}，库存不足!`)
                return false
            }
            // 重复资产不用添加
            let asset = this.dialogData.returnItems.find(x => {
                return x.assetId == item.id
            })
            if (asset) {
                if (!callback) {
                    this.warningMsg(`${asset.assetName}，已存在资产列表!`)
                }
                return false
            }
            let temp = {}
            temp.assetId = item.id
            temp.assetName = item.value // item.value
            temp.barCode = item.barCode
            temp.needSerialNumber = item.needSerialNumber
            temp.serialNumbers = item.serialNumbers || []
            temp.count = temp.needSerialNumber ? temp.serialNumbers.length : 1
            temp.imageUrl = item.imageUrl
            temp.category = item.category
            temp.maxCount = item.maxCount
            this.dialogData.returnItems.push(temp)
        },
        execSelect(callback) {
            // 扫码自动识别
            let obj = {}
            let searchDatas = []
            if (this.keyword) {
                var query = { 
                    categoryId: null,
                    companyId: "",
                    ownerType: this.valOwnerAssetUser() || null,
                    ownerId: this.dialogData.assetUserId || "",
                    keywords: this.keyword,
                    maxResultCount: 20
                }
                funStock.getStockSearch(query).then(res => {
                    for (var i in res) {
                        var item = res[i]
                        var temp = {}
                        temp.id = item.id
                        temp.value = item.name
                        temp.barCode = item.barCode
                        temp.imageUrl = item.imageUrl || ""
                        temp.needSerialNumber = item.hasSerialNumber
                        temp.category = item.category
                        temp.maxCount = this.dialogData.assetUserId ? item.stock : -1 // 设置为-1，表示不需要控制最大数量
                        searchDatas.push(temp)
                    }
                    if (searchDatas.length == 1) {
                        obj = searchDatas[0]
                        this.handleSelect(obj, callback)
                        callback && callback(res[0])
                    } else if (searchDatas.length > 1) {
                        this.warningMsg("资产选项不止一个，请手动选择!")
                        return false
                    } else {
                        this.errorMsg("不存在当前资产信息!")
                        return false
                    }
                }).catch(e => {
                    this.$alert('资产加载出错!')
                    return false
                })
            }
        },
        _delete(row, index) {
            this.confirm("确定要删除当前数据吗？").then(() => {
                this.dialogData.returnItems.splice(index, 1)
            })
        },
        _save() {
            if (!this.dialogData.id) {
                this.warningMsg("请通过选择归还人，查询相关更换单数据!")
                return false
            }
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = {
                        billId: this.dialogData.id,
                        returnItems: this.dialogData.returnItems.map(x => {
                            let temp = { 
                                assetId: x.assetId, 
                                count: x.count, 
                                serialNumbers: x.serialNumbers 
                            }
                            return temp
                        }) || [],
                        remark: this.dialogData.remark
                    }
                    // 完成更换
                    window.$common.fullLoading()
                    funStock.completeExchangeStock(commitData).then((res) => {
                        window.$common.closeFullLoading()
                        this.commonSuccess("操作成功!", res)
                    })
                } else {
                    return false
                }
            })
        },
        openScanCode(row) {
            this.$refs[this.refToScanSerial].open({ ownerId: this.dialogData.assetUserId, ownerType: this.valOwnerAssetUser() }, row)
        },
        setAssetDatas(datas, curProduct) {
            // 有产品的情况下，如果扫码数据为空
            if (curProduct && datas.length <= 0) {
                let assetIndex = this.dialogData.returnItems.findIndex(x => {
                    return x.assetId == curProduct.assetId
                })
                if (assetIndex != -1) {
                    let tempItem = JSON.parse(JSON.stringify(this.dialogData.returnItems[assetIndex]))
                    tempItem.serialNumbers = []
                    tempItem.count = tempItem.serialNumbers.length
                    this.dialogData.returnItems.splice(assetIndex, 1, tempItem)
                }
                return false
            }
            // 扫码回传数据进行合并数据
            for (let i in datas) {
                let item = datas[i]
                // 判断当前数据是否在商品中存在
                let assetIndex = this.dialogData.returnItems.findIndex(x => {
                    return x.assetId == item.assetId
                })
                // 存在则先判断资产是否相同
                if (assetIndex != -1) {
                    let tempItem = JSON.parse(JSON.stringify(this.dialogData.returnItems[assetIndex]))
                    // 如果是通过产品进入的扫码，回来就
                    if (curProduct) {
                        tempItem.serialNumbers = []
                    }
                    datas.forEach((tp) => {
                        if (tp.assetId == item.assetId && tempItem.serialNumbers.indexOf(tp.serialNumber) === -1) {
                            tempItem.serialNumbers.push(tp.serialNumber)
                        }
                    })
                    tempItem.count = tempItem.serialNumbers.length
                    this.dialogData.returnItems.splice(assetIndex, 1, tempItem)
                } else {
                    item.serialNumbers = [item.serialNumber]
                    item.count = item.serialNumbers.length
                    this.dialogData.returnItems.push(item)
                }
            }
        }
    }
}
</script>